<template>
  <div>
    <b-container>
      <!-- alinha o card do form de edição com os Breadcrumbs, tirando o padding nos dois -->
      <Form
        ref="form"
        :editing="true"
        :tipoMaterial="tipoMaterial"
        @modelUpdated="modelUpdated"
      />
      <Modal
        id="modalEtiqueta"
        ref="modalEtiqueta"
        nome="modalEtiqueta"
        title="Impressão de etiqueta"
        no-close-on-esc
        no-close-on-backdrop
        size="md"
        centered
      >
        <b-container>
          <Printer
            buttonName="Imprimir etiqueta"
            :stringZebra="stringZebra"
            fixedContent
          >
            <template #printContent class="qrCode-printer">
              <div id="qrCode" class="text-center qrCode-div">
                <qrcode-vue
                  :value="formModel.codigo || formModel.code"
                  level="H"
                  class="qr-code"
                  renderAs="svg"
                  size="75"
                ></qrcode-vue>
                <label class="qr-code-label mb-4 descricao-material">
                  {{ labelQr }}
                </label>
                <br />
                <label class="qr-code-label mb-4 qrCode-INVCXCuuid no-print">
                  {{ formModel.codigo || formModel.code }}
                </label>
                <qrcode-vue
                  :value="formModel.codigo || formModel.code"
                  level="H"
                  class="qr-code printOnly"
                  renderAs="svg"
                  size="75"
                ></qrcode-vue>
                <label class="qr-code-label mb-4 descricao-material printOnly">
                  {{ labelQr }}
                </label>
                <qrcode-vue
                  :value="formModel.codigo || formModel.code"
                  level="H"
                  class="qr-code printOnly"
                  renderAs="svg"
                  size="75"
                ></qrcode-vue>
                <label class="qr-code-label mb-4 descricao-material printOnly">
                  {{ labelQr }}
                </label>
                <br />
              </div>
            </template>
          </Printer>
          <div class="modal-register-new-button">
            <Button
              variant="secondary"
              text="Cadastrar novo material"
              @click="goToRegisterPage"
            />
          </div>
        </b-container>
      </Modal>
    </b-container>
  </div>
</template>

<script>
import moment from 'moment';
import QrcodeVue from 'qrcode.vue';
import Modal from '@/components/Utils/Modal';
import Button from '@/components/Utils/Button';
import Form from './Form';

import Printer from '../../components/Utils/Printer';

export default {
  components: {
    Form,
    QrcodeVue,
    Printer,
    Modal,
    Button,
  },
  props: {
    tipoMaterial: {
      type: String,
    },
  },

  computed: {
    formModel() {
      return this.$refs.form ? this.$refs.form.model : {};
    },
    labelQr() {
      if (!this.formModel.descricao) return '';
      const id = this.formModel.id ? `${this.formModel.id} - ` : '';
      const descricao = this.formModel.descricao.length < 60
        ? this.formModel.descricao
        : this.formModel.descricao.substr(0, 57).concat('...');

      const nomeMedico = this.nomeMedico.length < 40
        ? this.nomeMedico
        : this.nomeMedico.substr(0, 37).concat('...');

      const nomeFornecedor = this.nomeFornecedor.length < 40
        ? this.nomeFornecedor
        : this.nomeFornecedor.substr(0, 37).concat('...');

      return `${id}${descricao} ${nomeMedico}${nomeFornecedor}`;
    },
  },
  data() {
    return {
      nomeMedico: '',
      nomeFornecedor: '',
      stringZebra: '',
      // lastCiclo: {},
    };
  },

  mounted() {
    if (this.$route.query.created) {
      this.showModalEtiqueta();
    }
  },

  methods: {
    goToRegisterPage() {
      const { model } = this.$refs.form;
      const idTipoMaterial = model.id_tipo_material;
      if (+idTipoMaterial === 2) {
        this.$router.push('/materialMedico/adicionar');
      } else this.$router.push('/material/adicionar');
    },

    showModalEtiqueta() {
      this.$refs.modalEtiqueta.show();
    },

    modelUpdated(model) {
      // this.lastCiclo = model.lastCiclo;
      this.stringZebra = model.stringZebra;
      this.nomeMedico = model.medico_nome ? `- ${model.medico_nome}` : '';
      this.nomeFornecedor = model.fornecedor_nome ? `- ${model.fornecedor_nome}` : '';
    },

    moment,
  },
};
</script>

<style lang="scss" scoped>
$neutral-color-80: #35384d;

.modal-register-new-button {
  position: absolute;
  left: 14px;
  bottom: 16px;
}

.info-ciclo-card .invision-btn-light {
  position: absolute;
  right: 36px;
  transform: translate(0, -15%);
}

.status-row {
  padding-top: 20px;
}

.status-tag {
  background-color: rgba(46, 155, 229, 0.3);
  color: #0093e5;
  font-weight: 500;
  border-radius: 5px;
  padding: 2px;
  margin-top: 2px;
  text-align: center;
}

.status-tag-warning {
  background-color: rgba(228, 13, 31, 0.719);
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  width: 80%;
  padding: 2px;
  margin-top: 2px;
}

.font-style-medium {
  font-weight: 600;
  margin-top: 4px;
}

.p-card {
  padding: 36px;
  margin-bottom: 20px;
}

@media print {
  .qrCode-printer {
    height: 10.4cm !important;
    width: 2.85cm !important;
    padding: 0.2cm !important;
    padding-top: 0.5cm !important;
  }

  .qrCode-div {
    // display: grid;
    // grid-template-rows: repeat(3, 300px 1fr);
    margin-bottom: 2.3cm !important;
  }

  .qr-code {
    margin-left: 0.55cm !important;
    height: 2cm !important;
    width: 2.2cm !important;
  }

  .descricao-material {
    width: 100% !important;
    font-size: 0.3cm !important;
    height: 0.6cm !important;
    margin-top: 0cm !important;
    margin-left: 0.1cm !important;
    margin-bottom: 2.9cm !important;
  }

  .qrCode-INVCXCuuid {
    width: 100% !important;
    font-size: 0.26cm !important;
    height: 1.5cm !important;
    margin-left: 0.1cm !important;
  }

  .printOnly {
    display: inline-block !important;
  }
}

.qr-code-label {
  margin: auto;
  font-style: italic;
  color: #8f8f8f;
}

.printOnly {
  display: none;
}

.card.invision-card.invision-top {
  border-top: 0 !important;
}

h5 {
  // titulo cabeçalho modal
  font-size: 18px;
  color: $neutral-color-80;
}

button {
  margin: auto;
}

.w-52 {
  width: 95%;
}

.tabela {
  background-color: white;
}

@media print {
  .no-print {
    display: none;
  }
}

.main-content {
  width: 40em;
  border: 2px dashed rgb(178, 176, 215);
  border-radius: 5px;
  padding: 5%;
}

.span-text {
  color: #00daae;
}
</style>
