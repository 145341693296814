<template>
  <div>
    <b-row class="mt-3">
      <b-col sm="12" md="12" lg="12" xl="12">
        <h4>Descrição dos itens</h4>
        <br />
        <b-table
          :fields="fieldsItens"
          :items="model.itens"
          hover
          responsive
          foot-clone
        >
          <template #cell(fotos)="{ value }">
            <eye-icon v-if="value.length"
              class="pointer"
              @click="$emit('open-modal-foto', value)"
            />
          </template>
          <template #cell(id_item)="{ value }">
            <edit-icon
              class="pointer"
              @click="handleEditarItem(value)"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <trash-2-icon
              class="pointer"
              @click="handleRemoveItem(value)"
            />
          </template>

          <template #foot(fotos)>{{''}}</template>
          <template #foot(id_item)>{{''}}</template>
          <template #foot(code)>{{''}}</template>
          <template #foot(quantidade_pecas_item)>{{''}}</template>
          <template #foot(fabricante)>{{''}}</template>
          <template #foot(descricao)>
            <Button
              icon="plus"
              text="Ler novo item"
              variant="grayscale"
              type="text"
              @click="handleAddItemButton"
            />
          </template>
        </b-table>
      </b-col>
    </b-row>
    <Modal
      id="modalAddItem"
      ref="modalAddItem"
      centered
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
    >
      <b-row>
        <b-col>
          <b-form-group
            label="Realize a leitura do novo instrumento"
            label-for="codigo_novo_instrumento"
            label-class="float-left"
          >
          <info-icon
            v-b-tooltip.hover.right="'Este campo não é obrigatório'"
            class="tooltip-icon"
          />
            <b-form-input
              id="codigo_novo_instrumento"
              name="codigo_novo_instrumento"
              v-model="newItem.code"
              autofocus
              autocomplete="off"
              class="invision-input"
              @input="handleChangeNewCode"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group
            label="Tipo de instrumento*"
            label-for="tipoItem"
          >
            <GenericSelectSearch
              route="tipoItem/listWithSearch"
              name="tipoItem"
              labelKey="descricao"
              idKey="id_tipo_item"
              @input="instrumentoSelect"
              :minCharSearch="3"
              v-validate="{ required: true }"
              :state="validateNewItem('tipoItem')"
              :value="newItem.tipoItem"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Fabricante*" label-for="fabricante">
            <GenericSelect
              name="fabricante"
              labelKey="nome"
              ref="inputFabricante"
              key="fabricante"
              :value="(newItem.fabricante && newItem.fabricante.id_fabricante) || idPadraoFabricante"
              route="fabricante"
              v-validate="{ required: true }"
              :state="validateNewItem('fabricante')"
              @input="handleChangeFabricante"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Quantidade de peças do item" label-for="quantidade_pecas_item" label-class="float-left">
          <info-icon
            v-b-tooltip.hover.right="
              `Atenção! Esta é a quantidade de peças que o item possui.
               Não a quantidade deste item que o material possui.`
            "
            class="tooltip-icon"
          />
            <b-form-input
              v-model="newItem.quantidade_pecas_item"
              ref="quantidade_pecas_item"
              class="invision-input"
              :state="validateNewItem('quantidade_pecas_item')"
              name="quantidade_pecas_item"
              min=0
              v-validate="{ required: true, min_value: 1 }"
              type="number"
            >
              Digite a quantidade de peças que o item possui
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" align="start">
          <Button
            text="Voltar"
            variant="grayscale"
            type="text"
            class="ml-0"
            @click="handleCloseAddItem"
          />
        </b-col>
        <b-col cols="6" align="end">
          <Button
            text="Confirmar"
            @click="handleConfirmRegisterNewItem"
            :disabled="disabledSaveItem"
          />
        </b-col>
      </b-row>
    </Modal>
    <Modal
      id="modalEditItem"
      ref="modalEditItem"
      centered
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
    >
      <b-row>
        <b-col>
          <b-form-group
            label="Realize a leitura do novo instrumento"
            label-for="codigo_novo_instrumento"
            label-class="float-left"
          >
          <info-icon
            v-b-tooltip.hover.right="'Este campo não é obrigatório'"
            class="tooltip-icon"
          />
            <b-form-input
              id="codigo_novo_instrumento"
              name="codigo_novo_instrumento"
              v-model="editItem.code"
              autofocus
              autocomplete="off"
              class="invision-input"
              @input="handleChangeNewCodeEdit"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group
            label="Tipo de instrumento*"
            label-for="tipoItem"
          >
            <GenericSelectSearch
              route="tipoItem/listWithSearch"
              name="tipoItem"
              labelKey="descricao"
              idKey="id_tipo_item"
              @input="instrumentoSelect"
              :searchInitial="true"
              :initialLabel="editItem.descricao"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Fabricante*" label-for="fabricante">
            <GenericSelect
              name="fabricante"
              labelKey="nome"
              ref="inputFabricante"
              key="fabricante"
              route="fabricante"
              :value="editItem.id_fabricante"
              v-validate="{ required: true }"
              :state="validateNewItem('fabricante')"
              @input="handleChangeFabricante"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Quantidade de peças do item" label-for="quantidade_pecas_item" label-class="float-left">
          <info-icon
            v-b-tooltip.hover.right="
              `Atenção! Esta é a quantidade de peças que o item possui.
              Não a quantidade deste item que o material possui.`
            "
            class="tooltip-icon"
          />
            <b-form-input
              v-model="editItem.quantidade_pecas_item"
              ref="quantidade_pecas_item"
              class="invision-input"
              :state="validateNewItem('quantidade_pecas_item')"
              name="quantidade_pecas_item"
              min=0
              v-validate="{required:true, min_value: 1}"
              type="number"
            >
              Digite a quantidade de peças que o item possui
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" align="start">
          <Button
            text="Voltar"
            variant="grayscale"
            type="text"
            class="ml-0"
            @click="handleCloseEditItem"
          />
        </b-col>
        <b-col cols="6" align="end">
          <Button
            text="Confirmar"
            @click="handleConfirmRegisterAlterItem"
            :disabled="disabledSaveItem"
          />
        </b-col>
      </b-row>
    </Modal>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import genericRequest from '@/services/genericRequest';
import { debounce } from '@/helpers/common';

import GenericSelect from '@/components/Form/GenericSelect';
import GenericSelectSearch from '@/components/Form/GenericSelectSearch';

import Modal from '@/components/Utils/Modal';
import Button from '@/components/Utils/Button';

export default {
  name: 'ComposicaoItens',
  inject: [
    'parentValidator',
  ],
  components: {
    Modal,
    Button,
    GenericSelect,
    GenericSelectSearch,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      idPadraoFabricante: null,
      newItem: {},
      editItem: {},
      fieldsItens: [
        {
          label: 'Tipo de instrumento',
          key: 'descricao',
          formatter: (value, key, item) => (item.tipoItem ? item.tipoItem.descricao : item.descricao),
        },
        {
          label: 'Quantidade de peças do item',
          key: 'quantidade_pecas_item',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Visualizar',
          key: 'fotos',
          tdClass: 'text-center',
          thClass: 'text-center',
          formatter: (value, key, item) => item.tipoItem.fotoTipoItem,
        },
        {
          label: 'Fabricante',
          key: 'fabricante',
          formatter: (value, key, item) => (item.fabricante ? item.fabricante.nome : ''),
        },
        {
          label: 'Editar  Excluir',
          key: 'id_item',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      disabledSaveItem: false,
    };
  },

  computed: {
    ...mapState([
      'configuracao',
    ]),
  },

  created() {
    this.$validator = this.parentValidator;
  },

  methods: {
    async handleRemoveItem(id_item) {
      const confirmRemove = await swal({
        text: 'Tem certeza que deseja remover o instrumento?',
        icon: 'warning',
        title: 'Confirmação',
        buttons: { cancel: 'Cancelar', confirm: 'Remover' },
      });
      if (confirmRemove) {
        this.$emit(
          'update-model',
          'itens',
          this.model.itens.filter((item) => item.id_item !== id_item),
        );
      }
    },

    handleAddItemButton() {
      this.idPadraoFabricante = this.configuracao.id_fabricante_padrao_itens
        ? parseInt(this.configuracao.id_fabricante_padrao_itens, 10) : null;
      this.newItem.quantidade_pecas_item = this.configuracao.qt_padrao_itens_material_composicao
        ? parseInt(this.configuracao.qt_padrao_itens_material_composicao, 10) : null;
      this.disabledSaveItem = false;
      this.$refs.modalAddItem.show();
    },

    handleChangeNewCode: debounce(function debouncing(code) {
      this.handleDetectNewCode(code);
    }, 500),

    handleChangeNewCodeEdit(code) {
      this.handleDetectNewCode(code, true);
    },

    async handleDetectNewCode(code, edit) {
      try {
        if (!code) {
          this.disabledSaveItem = false;
          return;
        }
        if (edit) {
          const codeLido = this.model.itens.filter((i) => i.code === code);

          if (codeLido.length
          && codeLido[0].id_item !== this.editItem.id_item) {
            this.disabledSaveItem = true;
            swal({
              title: 'Erro',
              text: 'Instrumento com o mesmo código já foi lido',
              icon: 'error',
              button: 'Continuar...',
            });
            return;
          }

          if (this.editItem.id_item
          && codeLido.length
          && codeLido[0].id_item
          && this.editItem.id_item === codeLido[0].id_item) {
            this.disabledSaveItem = false;
            return;
          }
        }

        this.disabledSaveItem = true;
        const existsCode = this.model.itens.some((i) => i.code === code);

        if (existsCode && !edit) {
          swal({
            title: 'Erro',
            text: 'Instrumento com o mesmo código já foi lido',
            icon: 'error',
            button: 'Continuar...',
          });
          return;
        }

        const readItem = await this.getNewItem(code);
        if (readItem === 'OK') {
          this.newItem = {
            code: this.newItem.code,
            id_item: `new-${Date.now()}`,
          };
          this.disabledSaveItem = false;
          if (this.newItem) {
            this.newItem.quantidade_pecas_item = this.configuracao.qt_padrao_itens_material_composicao
              ? parseInt(this.configuracao.qt_padrao_itens_material_composicao, 10) : null;
          }
          return;
        }

        const existsItem = this.model.itens.some((i) => i.id_item === readItem.id_item);

        if (existsItem) {
          swal({
            title: 'Erro',
            text: 'Instrumento já foi lido',
            icon: 'error',
            button: 'Continuar...',
          });
          return;
        }

        if (readItem && !edit) {
          this.newItem = {
            descricao: readItem.descricao,
            fabricante: {
              nome: readItem.fabricante.nome,
              id_fabricante: readItem.id_fabricante,
            },
            id_item: readItem.id_item,
            code: readItem.code,
            quantidade_pecas_item: readItem.quantidade_pecas,
            tipoItem: readItem.tipoItem,
          };
          this.$emit('update-model', 'itens', [
            { ...this.newItem },
            ...this.model.itens,
          ]);
        }

        if (!edit) {
          this.handleCloseAddItem();
        } else {
          // this.handleCloseEditItem();
        }
      } catch (error) {
        console.log('error ao buscar item. error:', error);
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        } else {
          errorMessage = 'Ocorreu um problema ao recuperar o instrumento lido';
        }

        swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        this.newItem = { code: '' };
      }
    },

    getNewItem(code) {
      return genericRequest.get({}, `item/byCode/${code}/materialComposition`);
    },

    handleCloseAddItem() {
      this.newItem = {};
      this.$refs.modalAddItem.hide();
    },

    instrumentoSelect(itemSelected) {
      this.newItem.tipoItem = itemSelected;
      this.newItem.descricao = itemSelected ? itemSelected.descricao : '';
    },

    validateNewItem(ref) {
      if (
        this.veeFields[ref]
        && document.getElementsByName(ref)[0]
        && !document.getElementsByName(ref)[0].disabled
        && (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }

      return null;
    },

    async handleConfirmRegisterNewItem() {
      if (!this.newItem.id_item) {
        this.newItem = {
          ...this.newItem,
          id_item: `new-${Date.now()}`,
        };
      }

      const validations = await this.$validator.validateAll();
      if (validations) {
        if (!this.newItem.fabricante) {
          this.handleChangeFabricante(this.idPadraoFabricante);
        }
        this.$emit('update-model', 'itens', [
          { ...this.newItem },
          ...this.model.itens,
        ]);
        this.handleCloseAddItem();
      }
    },

    handleEditarItem(id_item) {
      const item = this.model.itens.find((i) => i.id_item === id_item);

      if (item) {
        this.editItem = { ...item };
      }

      this.$refs.modalEditItem.show();
    },

    handleCloseEditItem() {
      // this.editItem = this.model.itens.find((item) => item.id_item === id_item);

      this.editItem = {};
      this.$refs.modalEditItem.hide();
    },

    async handleConfirmRegisterAlterItem() {
      const validations = await this.$validator.validateAll();
      if (validations) {
        this.model.itens.forEach((itemUpdate, index) => {
          if (itemUpdate.id_item === this.editItem.id_item) {
            // eslint-disable-next-line vue/no-mutating-props
            this.model.itens[index] = { ...this.editItem };
          }
        });
        this.$emit('update-model', 'itens', [
          ...this.model.itens,
        ]);
        this.handleCloseEditItem();
      }
    },

    handleChangeFabricante(id_fabricante) {
      const fabricante = this.$refs.inputFabricante.apiResponse.find((f) => f.id_fabricante === id_fabricante);
      this.newItem = { ...this.newItem, fabricante };
    },
  },
};
</script>

<style>

.tooltip-icon {
  width: 16px;
  margin-left: 0.5rem;
  vertical-align: text-bottom;
  color: #35384d;
}

</style>
